<template lang="html">
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    v-if="readingguide"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ readingguide.title }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" v-html="readingguide.content"></div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Sluiten
          </button>
        </div>
      </div>
    </div>
  </div>
  <section class="navbar-section">
    <div class="container">
      <nav class="navbar navbar-expand-lg">
        <div class="container p-0">
          <a class="navbar-brand" href="/">
            <img
              src="../assets/logo.svg"
              alt="Bootstrap"
              width="141"
              height="70"
            />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav me-auto">
              <!-- <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="/">Home</a>
            </li> -->
            </ul>
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link
                  class="nav-link me-2"
                  :class="{ 'nav-link-active': isActive('people') }"
                  to="/people"
                  >People</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link me-2"
                  :class="{ 'nav-link-active': isActive('planet') }"
                  to="/planet"
                  >Planet</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link me-2"
                  :class="{ 'nav-link-active': isActive('profit') }"
                  to="/profit"
                  >Profit</router-link
                >
              </li>
              <li class="nav-item" v-if="readingguide">
                <a
                  class="nav-link nav-link-alt me-2"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  >Leeswijzer</a
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
export default {
  name: "NavBar",
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    readingguide() {
      return this.$store.getters.getReadingguide;
    },
  },
  methods: {
    isActive(name) {
      if (this.$route.name == name) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="css" scoped>
.navbar-section {
  background-color: #f5f9f0;
}
.nav-link-active {
  color: #000;
  text-decoration: underline;
}
.nav-link-alt {
  color: #789c4c;
}
</style>
