<template>
  <section class="section_color p-4 mb-3">
    <div class="mx-auto d-block text-center">
      <!-- kaas
    {{ getPillarContent.social_development_goals }}
    baas https://mvo.quantes.nl/db/storage/uploads/ -->
      <img
        :src="
          'https://mvo.quantes.nl/db/storage/uploads/' + goal.sdg_image.path
        "
        class="rounded mx-auto mt-2 mb-2 me-2 image"
        width="125"
        v-for="goal in goals"
        v-bind:key="goal"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "GoalsComponent",
  props: {
    goals: Object,
  },
};
</script>

<style lang="css" scoped>
.section_color {
  background-color: #f5f9f0;
}
.image {
  filter: grayscale(1);
  /* filter: sepia(75%); */

  opacity: 0.3;
}
.image:hover {
  filter: none !important;
  opacity: 1 !important;
}
</style>
