<template>
  <section class="planet-section">
    <div class="container planet-container">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1>Hoe wij de milieu-impact beperken</h1>
          <p class="lead">
            Bij de realisatie van marketing producties en grafische uitingen,
            kunnen we actief sturen op een duurzaam gebruik van energie en
            grond- en hulpstoffen. Omdat we inzake inkoop, productie en vervoer
            al vele jaren beschikken over managementsystemen ter beperking van
            de milieu-impact.
          </p>
          <a type="button" class="btn btn-primary text-white" href="/planet">
            Lees meer...
          </a>
        </div>
        <div class="col-md-4 align-middle">
          <PlanetImg />
        </div>
      </div>
      <div class="row">
        <ul class="nav nav-tabs justify-content-center">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="[getActiveYear == 2022 ? 'active' : '']"
              aria-current="page"
              @click="setYear(2022)"
              >2022</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="[getActiveYear == 2023 ? 'active' : '']"
              @click="setYear(2023)"
              >2023</a
            >
          </li>
        </ul>
      </div>
      <div class="row pt-5">
        <div class="col-md-4">
          <h3 class="overlay">Papierverbruik</h3>
          <div class="ps-2 pe-2"><PaperUsage /></div>
        </div>
        <div class="col-md-4">
          <h3 class="overlay">Gas</h3>
          <div class="ps-2 pe-2"><GasInfo /></div>
        </div>
        <div class="col-md-4">
          <h3 class="overlay">Elektriciteit</h3>
          <div class="ps-2 pe-2"><ElectricityInfo /></div>
        </div>
      </div>
      <!-- <div class="row pt-5">
        <div class="col-md-12 text-center">
          <div class="d-grid">
            <button type="button" class="btn btn-primary text-white">
              Acties en maatregelen in 2023
            </button>
          </div>
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
import PlanetImg from "@/components/PlanetImg.vue";
import PaperUsage from "@/components/PaperUsage.vue";
import GasInfo from "@/components/GasInfo.vue";
import ElectricityInfo from "@/components/ElectricityInfo.vue";

export default {
  name: "PlanetSection",
  components: {
    PlanetImg,
    PaperUsage,
    GasInfo,
    ElectricityInfo,
  },
  computed: {
    getActiveYear() {
      return this.$store.getters.getActiveYear;
    },
  },
  methods: {
    setYear(year) {
      this.$store.commit("setActiveYear", year);
    },
  },
};
</script>

<style scoped>
.planet-section {
  background-color: #f5f9f0;
  padding: 2em;
}
.planet-container {
  padding: 2em;
}
.overlay {
  position: absolute;
  visibility: visible;
}
.nav-tabs .nav-link.active {
  background-color: #f5f9f0 !important;
  border-bottom-color: #f5f9f0;
}
</style>
